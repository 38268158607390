import { ApolloError } from "@apollo/client";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";

export const useErrorToast = () => {
  const { showToast } = useCourieStore();

  const showError = (error: ApolloError) => {
    showErrorToast(error, showToast);
  };

  return showError;
};
